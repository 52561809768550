<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Consider the following reaction:</p>

      <p class="mb-5 pl-8">
        <stemble-latex
          content="$\ce{2SO2(g) + O2(g) <=> 2 SO3(g)} \hspace{1.0 cm} \text{K}_\text{p} = 3.0\times 10^{4} \, \text{at 700.} \, \text{K}$"
        />
      </p>

      <p class="mb-2">
        a) Without doing any calculations, what is the sign of
        <stemble-latex content="$\Delta\text{G}^\circ$" />
        for the reaction? Explain.
      </p>

      <p class="mb-1">
        <s-textarea v-model="inputs.signDeltaG" outlined rows="3" />
      </p>

      <p class="mb-2">
        b) Without doing any calculations, are there more products or reactants present at
        equilibrium. Explain.
      </p>

      <p class="mb-1">
        <s-textarea v-model="inputs.productsVsReactants" outlined rows="3" />
      </p>

      <p class="mb-2">
        c) What is the value of
        <stemble-latex content="$\Delta\text{G}^\circ$" />
        for this reaction in kJ/mol?
      </p>

      <calculation-input
        v-model="inputs.deltaG"
        class="mb-0"
        prepend-text="$\Delta\text{G}^\circ_\text{rxn}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'cpp1220Exam3Q4',
  components: {
    STextarea,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        signDeltaG: null,
        productsVsReactants: null,
        deltaG: null,
      },
    };
  },
};
</script>
